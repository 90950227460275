<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <a-row>
        <a-col :span="12">
          <a-form-item label="字段名" name="optionName"
            :rules="[{ required: true, message: '请选择字段名' }]">
            <a-select v-model:value="form.optionName" placeholder="请选择字段名" allow-clear show-search
              :filter-option="filterOption" style="width: 100%">
              <a-select-option v-for="option in list" :key="option" :name="option"
                :disabled="option.disabled">{{ option }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="字段值" name="optionValue" :rules="[{ required: true, message: '请输入字段值' }]">
            <a-input allow-clear v-model:value.trim="form.optionValue" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="排序" name="sort">
            <a-input-number class="sortInputNumber" controls v-model:value="form.sort">
                <template #addonBefore>
                  <span
                    @click="aInputNumberStep(form.sort + 1, { offset: 1, type: 'up' })">+</span>
                </template>
                <template #addonAfter>
                  <span
                    @click="aInputNumberStep(form.sort - 1, { offset: 1, type: 'down' })">-</span>
                </template>
              </a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-for="(item, index) in form.childrenList" :key="index">
        <a-col :span="12">
          <a-form-item :name="item.name + index">
            <div class="label-style" slot="label">
              <div>
                <span>子项{{ index + 1 }}</span>
                <DeleteOutlined @click="deleteSubItem(index)"
                  style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
              </div>
            </div>
            <a-input allow-clear v-model:value.trim="item.name" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-button @click="addManage" type="primary" ghost style="margin-bottom: 20px;">+ 添加子项</a-button>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button type="primary" @click="submit" :loading="submitLoading">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
// import { starRating, productType } from '@/utils/public';
import dayjs from 'dayjs';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      list: [],
      form: {
        childrenList: []
      },
      detail: {}
    }
  },
  created() {
    this.getList();
    this.getDetail();
  },
  methods: {
    disabledDate(time) {
      return time > Date.now();
    },

    async getList() {
      try {
        const res = await this.$http.get('/option/config/getOptionNameList');
        this.list = res.data;
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async getDetail() {
      try {
        const res = await this.$http.get('/option/config/update/get',{
          id: this.selectedRows[0].id
        });
        this.detail = res.data;
        this.form.optionName = res.data.optionName;
        this.form.optionValue = res.data.optionValue;
        this.form.sort = res.data.sort;
        this.form.childrenList = res.data.childrenList;
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },
    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      this.submitLoading = true;
      let { optionName, optionValue, childrenList, sort } = this.form;
      try {
        await this.$http.post('/option/config/update', { id: this.selectedRows[0].id, optionName, optionValue, oldOptionValue: this.detail.optionValue, childrenList: JSON.stringify(childrenList), sort});
        this.$message.success('提交成功');
        this.$refs.form.resetFields();
        this.$emit('close');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    handleOk() {
      this.openModal = false;
    },

    addManage() {
      if (this.form.childrenList.length < 5) {
        this.form.childrenList.push({
          name: ''
        })
      } else {
        return
      }
    },

    deleteSubItem(index) {
      this.form.childrenList.splice(index, 1)
    },

    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    aInputNumberStep(value, status, KEY) {
      if (status.type == 'down') {
        isNaN(value) ? this.form.sort = - 1 : this.form.sort = value
      } else {
        isNaN(value) ? this.form.sort = 1 : this.form.sort = value
      }
    },
  }
}
</script>

<style scoped lang="scss">
.blueCopy {
  color: blue;
  text-decoration: underline;
}

.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}

.ma-col {
  border: 1px solid #d9d9d9;
  padding: 20px 20px 0 20px;
  border-radius: 8px;

  .ms-flex {
    display: flex;
    margin-bottom: 20px;
  }

  .ms-title {
    width: 90px;
    text-align: right;

    flex-shrink: 0;
  }

  .ms-content {
    flex-shrink: 0;
    width: calc(100% - 130px);
  }

  .ms-scroll {
    flex-shrink: 0;
    width: calc(100% - 130px);
    max-height: 200px;
    overflow-y: scroll;
  }

  .ms-img {
    width: 160px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}

:deep(.sortInputNumber.ant-input-number .ant-input-number-input) {
  text-align: center;
}

.sortInputNumber {
  span {
    cursor: pointer;
  }
}
</style>
